import { IService } from '@/interfaces/service';

export const crioterapie: IService = {
    title: 'Crioterapie',
    shortDescription: 'Crioterapie pentru recuperare medicală',
    description: '<h2><span class="text-primary">Crioterapia</span> este o metodă de vindecare a leziunilor \
    (articulare sau musculare) prin care sunt utilizate aplicații externe reci asupra zonelor afectate. \
    Scopul acestei terapii este de a scădea durerea (analgezic), diminuarea procesului inflamator posttrauma \
    sau postoperator, putând fi utilizată totodată în refacerea și recuperarea sportivă de zi cu zi.</h2><br>\
    <p>De asemenea, aplicațiile locale reci creează vasoconstricția în țesuturile superficiale și scade \
    viteza de conducere nervoasă, având și efect asupra metabolismului local.</p>\
    <p class="pt-5 pb-1">Beneficii:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Reduce semnificativ durerea asociată afecțiunilor și zonelor traumatizate</li>\
    <li>Determină recuperarea din diferitele patologii sportive</li>\
    <li>Grăbește vizibil procesul de vindecare</li>\
    <li>Reduce inflamațiile și stimulează recuperarea după antrenamente</li>\
    <li>Scade timpul necesar recuperării</li>\
    <li>Favorizează circulația sangvină</li>\
    </ul><br> \
    <p><span class="text-primary">Game Ready GRPro 2.1</span> este un sistem inovator de recuperare prin \
    criocompresie, care ajută oamenii să se recupereze și să își revină după o accidentare sau o intervenție \
    chirurgicală. Mii de pacienți, sportivi de elită, echipe profesioniste, chirurgi ortopezi, medici \
    specialiști de medicină sportivă, kinetoterapeuți din întreaga lume au ales \
    <span class="text-primary">Game Ready</span> și îl folosesc de peste 15 ani.</p><br>\
    <p>Sistemul oferă o răcire mai rapidă, mai profundă și mai durabilă în comparație cu metodele \
    tradiționale <span class="text-primary">Rest-Ice-Compression-Elevation (RICE)</span> și alte dispozitive</p><br>\
    <p>Pentru leziuni acute și recuperare postoperatorie, sistemul de răcire și compresie \
    <span class="text-primary">GRPro® 2.1</span> 1 este dovedit clinic că ajută la reducerea \
    consumului de opioide, la scăderea durerii și a inflamațiilor, având un efect pozitiv asupra \
    procesului de recuperare și refacere postefort.</p>',
    photoUrl: 'DSC_1997.webp',
    videos: []
};