import { IService } from '@/interfaces/service';

export const testingArticular: IService = {
    title: 'Testing Articular',
    shortDescription: 'Testing Articular - Evaluarea gradului de mișcare al articulațiilor',
    description: '<h2>Cunoașterea gradului de mișcare a unei articulaţii sau a unui segment de articulații \
    este absolut necesară pentru a putea stabili un diagnostic funcțional. De aceea, \
    <span class="text-primary">MovKinetic</span> folosește cele mai eficiente metode de măsurare a modului \
    de mobilizare al unui segment articular pe toate direcțiile în mișcare. Astfel, terapeuții noștri \
    dezvoltă, în baza acestei tactici, un tratament complex pentru pacienții cu afecțiuni. \
    Împreună cu <span class="text-primary">testingul muscular</span>, <span class="text-primary">testarea articulară</span> \
    reprezintă o decizie esențială în diagnosticarea afecțiunilor și în modificarea obiectivelor de tratament propuse.</h2>',
    photoUrl: 'DSC_4193.webp',
    videos: []
};