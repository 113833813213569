import { IService } from '@/interfaces/service';

export const laserInaltaFrecventa: IService = {
    title: 'Laser înaltă frecvență',
    shortDescription: 'Laserterapia - Terapia cu Laser Înaltă Frecventă',
    description: '<h2>Terapeuții <span class="text-primary">MovKinetic</span> folosesc terapia cu laser de înaltă \
    frecvență, care permite pacientului să maximizeze rezultatele terapeutice și să grăbească procesul de vindecare. \
    Este o procedură sterilă și nedureroasă, care are efect analgezic puternic pentru diferite afecțiuni ale \
    țesuturilor. Creșterea energiei celulare, vindecarea rapidă a țesuturilor traumatizate, îmbunătățirea \
    metabolismului celular și creșterea fluxului sangvin la nivelul zonei afectate sunt principalele efecte pe termen \
    lung ale acestei proceduri.</h2><br>\
    <p>Această terapie are la bază emisiile de laser, având efect terapeutic puternic, eliberând beta-endorfinele, \
    îmbunătățind fluxul sangvin al zonei afectate.</p><br>\
    <p><span class="text-primary">Laserterapia</span> este indicată pentru următoarele afecțiuni: dureri musculare, \
    hernie de disc, dureri de spate, leziuni ale cartilajelor, artroză, traumatisme sportive, tendinite, \
    fasciite plantare, fracturi, luxații, leziuni musculare etc.</p>\
    <p>Este indicat atât în afecțiunile acute, cât și în cele cronice.</p>\
    <p class="pt-5 pb-1">Contraindicații:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Sarcină</li>\
    <li>Cancere</li>\
    <li>Medicamente fotosensibilizante (Roaccutane, Tertaciclină, Doxiciclină, medicamente antimalarice)</li>\
	<li>Medicamente anticoagulante (Heparină)</li>\
	<li>Boli fotosensibilizante (lupus eritematos)</li>\
	<li>Epilepsie</li>\
	<li>Leziuni traumatice la nivelul zonei de tratat</li>\
	<li>Utilizarea recentă de acid retinoic topic sau alfahidroxiacizi</li>\
	<li>Infecție recentă cu virusul herpetic la nivelul zonei de tratat</li>\
	<li>Antecedente de cicatrizare defectuoasă sau cicatrici cheloide prezente</li>\
	<li>Afecțiune dermatologică la nivelul zonei de tratat (eczemă, infecție)</li>\
	<li>Terapiile medicamentoase incompatibile cu terapia laser trebuie întrerupte conform specificațiilor \
    fiecărui medicament în parte.</li>\
    </ul>',
    photoUrl: 'DSC_4167.webp',
    videos: []
};