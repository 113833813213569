import { IService } from '@/interfaces/service';

export const recoveryBoots: IService = {
    title: 'Presoterapia',
    shortDescription: 'Presoterapia - Tehnologie modernă cu scop terapeutic',
    description: '<h2><span class="text-primary">Presoterapia</span> este o metodă de recuperare medicală modernă, \
    inovatoare și foarte puțin practicată în piața de profil din România. Procesul de realizare a drenajului limfatic, \
    pe care această metodă îl presupune, este complex, iar efectele sunt imediate și ajută la eliminarea toxinelor, \
    drenajul limfatic şi recuperarea membrelor după efort.</h2><br>\
    <p>Reprezinta o tehnologie avansată, creată în scop terapeutic. Folosește compresia progresivă, prin umflarea și \
    dezumflarea camerelor aflate într-un costum special, pentru a accelera viteza sângelui și a grăbi recuperarea.</p>\
    <p>Se utilizează atât după sesiunile de antrenament ale sportivilor, dar și ca parte integrată a sedințelor de kinetoterapie.</p>\
    <p class="pt-5 pb-1">Beneficii:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Crește circulația și drenajul limfatic</li>\
    <li>Reduce durerea musculară cu debut întârziat</li>\
    <li>Ameliorează oboseala musculară</li>\
    <li>Grăbește refacerea organismului postefort</li>\
    </ul> \
    <p class="pt-5 pb-1">Contraindicații în utilizare:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Acest sistem este destinat utilizării de către persoanele care au o stare bună de sănătate. Nu este recomandat \
    persoanelor care au probleme cardiace sau probleme vasculare, persoanelor care au o afecțiune ce necesită utilizarea \
    oricărui dispozitiv medical sau prezintă orice afecțiune care le poate afecta starea de sănătate normală.</li>\
    <li>Dacă sunteți sau umează să fiți gravidă, consultați-vă medicul înainte de utilizare.</li>\
    <li>Nu utilizați acest sistem în zone insensibile, amorțite sau în prezența unei circulații slabe. \
    Nu utilizați dispozitivul dacă ați fost diagnosticat cu cheaguri de sânge, tromboză venoasă profundă sau flebită. \
    Acest sistem nu trebuie utilizat peste zone umflate, inflamate sau cu erupții cutanate. A nu se utiliza în prezența \
    durerii inexplicabile a corpului superior.</li>\
    </ul>',
    photoUrl: 'DSC_1991.webp',
    videos: []
};