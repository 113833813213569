import { IService } from '@/interfaces/service';

export const drenajLimfatic: IService = {
    title: 'Drenaj Limfatic',
    shortDescription: 'Drenaj Limfatic - Stimularea circulației limfatice și sangvine',
    description: '<h2>Sistemul limfatic face parte din sistemul circulator și din sistemul imun al organismului. \
    Transportând limfa către inimă, reprezintă o rețea de ganglioni, vase limfatice, țesuturi limfatice și \
    organe limfatice. Limfa ajută la deversarea proteinelor și grăsimilor colectate înapoi în sânge, dar \
    și la izolarea patogenilor cauzatori de boală.</h2><br>\
    <p>Acest tip de recuperare medicală stimulează circulația limfei, astfel că, procesele de refacere \
    și de apărare ale organismului devin mai eficiente. La baza, <span class="text-primary">drenajul limfatic</span> \
    este o tehnică manuală, utilizată pentru a stimula fluxul limfei, cu scopul de a reduce inflamația, \
    accelera eliminarea toxinelor și de a îmbunătăți starea de sănătate. Cu ajutorul științei, tehnica \
    manuală a fost înlocuită în unele cazuri cu echipamente moderne, ce efectuează drenajul limfatic \
    utilizând manșoane ce îmbracă picioarele, abdomenul și brațele. Acest tip de costum este format \
    din mai multe spații pneumatice, în care aerul pătrunde intermitent cu o presiune controlată, \
    realizând în acest fel un masaj prin care se stimulează circulația limfatică și sangvină. \
    Terapeuții <span class="text-primary">MovKinetic</span> folosesc un astfel de echipament modern.</p>\
    <p class="pt-5 pb-1">Efectele drenajului limfatic:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Accelerează eliminarea toxinelor</li>\
    <li>Îmbunătățește starea sistemului limfatic</li>\
    <li>Îmbunătățește producția de limfocite (pentru a lupta cu infecțiile)</li>\
    <li>Accelerează procesul natural de regenerare a organismului</li>\
    <li>Detensionează și relaxează mușchii</li>\
    <li>Ajută la eliminarea retenției de apă</li>\
    <li>Diminuează inflamația după efort, intervenții chirurgicale sau accidentări</li>\
    <li>Tratează edemul periferic, inflamația tisulară generalizată de la nivelul membrelor \
    inferioare sau superioare, fără legătură cu sistemul limfatic.</li>\
    </ul> \
    <p class="pt-5 pb-1">Contraindicații:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Insuficiență cardiacă decompensată</li>\
    <li>Inflamații acute, cauzate de virusuri ori bacterii</li>\
    <li>Hipertiroidism </li>\
    <li>Astm bronșic </li>\
    <li>Tuberculoză</li>\
    <li>Emfizem pulmonar</li>\
    <li>Afecțiuni dermatologice</li>\
    <li>Boli contagioase.</li>\
    </ul>',
    photoUrl: 'DSC_1983.webp',
    videos: []
};