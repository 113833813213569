import { IService } from '@/interfaces/service';

export const ultrasunet: IService = {
    title: 'Ultrasunet',
    shortDescription: 'Terapia cu ultrasunet',
    description: '<h2 class="pb-1">Terapiile cu <span class="text-primary">ultrasunet</span> accelerează procesul de vindecare, \
    fiind creat un efect analgezic, miorelaxant. Astfel, pacienții tratați prin astfel de metode manifestă o \
    îmbunătățire a afecțiunilor și o ameliorare rapidă a durerilor din zonele traumatizate. \
    Pe tot parcursul acestei metode de recuperare, energia ultrasunetului este absorbită din \
    abundență de țesuturile care conțin colagen. <span class="text-primary">Tratamentul cu ultrasunete</span> \
    este recomandat în afecțiuni precum:</h2>\
    <ul class="list-disc px-10 text-left">\
    <li>Fracturi, entorse, luxații, hematoame, afecțiuni reumatismale </li>\
    <li>Sau cronic – în tendinite, leziuni musculare, reumatism cronic degenerativ, \
    cicatrici cheloide, nevralgii și nevrite.</li>\
    </ul>\
    <p class="pt-5 pb-1">Contraindicații:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Tulburări de coagulare a sângelui</li>\
    <li>Tumori maligne</li>\
    <li>Stări febrile</li>\
    <li>Modificări ale tegumentelor</li>\
    </ul>',
    photoUrl: 'DSC_2008.webp',
    videos: []
};