import { IService } from '@/interfaces/service';

export const testingMuscular: IService = {
    title: 'Testing Muscular',
    shortDescription: 'Testing Muscular - Evaluarea forței musculare',
    description: '<h2><span class="text-primary">Testing-ul Muscular</span> este realizat în cadrul clinicii \
    <span class="text-primary">MovKinetic</span>, utilizând o gamă complexă de tehnici de examinare manuală, \
    cât și stații robotice de evaluare. Aceste tehnici permit aprecierea forței unui mușchi sau grupe de muschi, \
    iar alături de testarea mobilității articulare, reprezintă baza evaluării funcționale a programelor de \
    kinetoterapie pe care le aplicăm pacienților noștri. Practic, bilanțul muscular reprezintă un sistem de \
    tehnici de examinare manuală/robotizată pentru evaluarea forței fiecărui mușchi sau a unor grupe \
    musculare, evaluarea fiind globală.</h2>\
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">De ce să faci testing muscular?</span></h3></p>\
    <p>Stă la baza alcătuirii unui program complex de recuperare medicală.</p>\
    <p>Stabilește rezultatele obținute prin aplicarea programelor de kinetoterapie.</p>\
    <p>Permite evaluarea corectă a sportivilor în perioda de pregătire, dar și în timpul competițiilor sportive.</p>\
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">Testele KINEO 7000 Multistation EPS și Kineo leg press</span></h3></p>\
    <p><h5 class="pb-2"><span class="text-primary">ISOKINETIC MAX STRENGTH BALANCE ASSESSMENT - Test de rezistență și echilibru izokinetice</span></h3></p>\
    <p>Test de rezistență izokinetică pentru a arăta deficitul de rezistență între membre (sănătos și rănit). \
    Execuție multiviteză în aceeași configurare - standardul perfect pentru evaluarea rezistenței.</p>\
    <p><h5 class="pt-6 pb-2"><span class="text-primary">ECCENTRIC STRENGTH EVALUATION - Testarea izokinetică a forței excentrice.</span></h3></p>\
    <p>Analiza completă cu vizualizarea graficelor și curbelor între membrul stâng și membrul drept. Cel mai sigur și mai precis mod de a \
    vă testa forța excentrică.</p>\
    <p><h5 class="pt-6 pb-2"><span class="text-primary">ISOKINETIC CONC-ECC RATIO TEST - Test izokinetic pentru evaluarea capacității concentrice</span></h3></p>\
    <p>Test izokinetic pentru evaluarea capacității concentrice și excentrice a mușchiului agonist. Analiza curbelor de forță și vizualizarea deficitului \
    dintre performanțele concentrice și excentrice</p>\
    <p><h5 class="pt-6 pb-2"><span class="text-primary">EVALUAREA PUTERII MAXIME ISOMETRICE</span></h3></p>\
    <p>Test de rezistență maximă izometrică multiunghi în aceeași sesiune. Vizualizare grafică și analiză a deficitului de forță între membrele stâng și drept.</p>\
    <p><h5 class="pt-6 pb-2"><span class="text-primary">TEST DE PUTERE</span></h3></p>\
    <p>Evaluază expresia puterii la diferite sarcini.</p>\
    <p>Vizualizare grafică a profilului viteză-putere, legat de sarcinile aplicate.</p>\
    <p>Rapid, personalizabil, eficient.</p>',
    photoUrl: 'DSC_1854.webp',
    videos: []
};