import { IService } from '@/interfaces/service';

export const stretching: IService = {
    title: 'Stretching',
    shortDescription: 'Exerciții de stretching',
    description: '<h2>Este o formă de exercițiu fizic, în care un anumit mușchi, tendon (sau grup muscular) este \
    flexat sau întins în mod deliberat pentru a îmbunătăți elasticitatea mușchiului și a obține un tonus \
    muscular confortabil. Rezultatul este o senzație de control muscular crescut, flexibilitate și creșterea \
    amplitudinii de mișcare. <span class="text-primary">Stretching-ul</span> este, de asemenea, utilizat \
    terapeutic pentru a atenua crampele și pentru a îmbunătăți funcția mușchiului/tendonului în activitățile zilnice.</h2><br>\
    <p><span class="text-primary">Exercițiile de stretching</span> sunt efectuate de către sportivi, de obicei la \
    începutul și la finalul antrenamentelor. Prin utilizarea acestei tehnici, sportivii își pot îmbunătăți \
    performanțele și pot evita accidentările datorită faptului că musculatura este pregătită pentru efort \
    sau refăcută postefort.</p><br>\
    <p>Însă acest tip de întindere a mușchilor este folosit în kinetoterapia de recuperare, în situații în \
    care se constată un deficit de mobilitate articulară. Așadar, terapeuții <span class="text-primary">MovKinetic</span> \
    utilizează frecvent această tehnică, cu scopul de a întinde în mod progresiv musculatura pacientului. Alături de tehnicile \
    propuse de terapeut, prin efectuarea <span class="text-primary">stretching-ului</span>, pacientul își va \
    detensiona musculatura și va putea efectua mișcări mai ample și corecte din punct de vedere biomecanic.</p><br>\
    <p><span class="text-primary">Stretching-ul</span> menține mușchii și tendoanele flexibile, puternice și \
    sănătoase, menținând amplitudinea mișcărilor. Fără <span class="text-primary">stretching</span>, \
    mușchii/tendoanele se scurtează și se rigidizează. Acest lucru vă pune în pericol integritatea mușchilor \
    și tendoanelor, iar în timp poate duce la leziuni musculare și leziunile tendoanelor.</p><br>\
    <p>În cabinetul <span class="text-primary">MovKinetic</span>, terapeuții noștri abordează tipuri \
    complexe de stretching, în funcție de afecțiunile si nevoile fiecărui pacient sau sportiv.</p>',
    photoUrl: '2.jpg',
    videos: []
};