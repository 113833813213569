import { IService } from '@/interfaces/service';

export const kinetoterapie: IService = {
    title: 'Kinetoterapie',
    shortDescription: 'Kinetoterapia în scop terapeutic și profilactic',
    description: '<h2><span class="text-primary">Kinetoterapia</span> este utilizată atât în scop terapeutic, cât și profilactic, \
    ajutând la menținerea stării de sănătate, la prevenirea deficiențelor fizice și la recuperarea funcţiilor organismului \
    afectate de anumite boli. Face parte din medicina fizică și studiază mecanismele neuromusculare și articulare care \
    îi asigură omului activitățile motrice normale. Are la bază mișcarea efectuată prin programe de recuperare medicală \
    bine structurate, ce au ca scop refacerea unor funcții diminuate (în recuperarea afecţiunilor locomotorii, cât şi în \
    tratarea unor afecţiuni ale organelor interne, boli cardiovasculare, neurologice, congenitale, respiratorii, metabolice, \
    oncologice şi chiar digestive).</h2><br>\
    <p><span class="text-primary">MovKinetic</span> are o echipa de kinetoterapeuți profesioniști care evaluează riguros \
    pacientul și elaborează programe și tratamente individualizate de recuperare atât pentru sportivi, cât și pentru \
    persoanele care necesită recuperare medicală.</p>\
    <p class="pt-5 pb-1">Beneficiile kinetoterapiei:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Elimină durerea și procesul inflamator</li>\
    <li>Redobândește forța musculară</li>\
    <li>Crește rezistența la efort și masa musculară</li>\
    <li>Corectează postura și aliniamentul corpului</li>\
    <li>Recuperează forța musculaturii afectate și ajută la recuperarea după accidente de diverse tipuri</li>\
    <li>Tratează tulburările nervoase și ajută la reeducarea aparatului respirator</li>\
    <li>Combate efectele îmbătrânirii și ale unui stil de viață nesănătos</li>\
    <li>Menține starea de bine a organismului</li>\
    <li>Îmbunătățește mobilitatea și amplitudinea articulară</li>\
    <li>Ajută la recuperarea rapidă a leziunilor sportive</li>\
    <li>Scade riscul de accidentare</li>\
    <li>Crește flexibilitatea musculaturii și a tendoanelor</li>\
    <li>Dezvoltă proprioceptia, echilibrul și coordonarea</li>\
    <li>Îmbunătățește funcția de coordonare</li>\
    </ul><br>\
    <p>În cadrul clinicii noastre, utilizăm <span class="text-primary">kinetoterapia</span> pentru tratarea afecțiunilor \
    coloanei vertebrale, celor de natura ortopedică, cât și pentru traumatologia sportivă.</p>\
    <p class="pt-5 pb-1">Câteva dintre cele mai des întâlnite patologii tratate în cadrul clinicii noastre:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Ruptura ligamentului încrucișat anterior/posterior</li>\
    <li>Leziuni de menisc</li>\
    <li>Sutură de menisc</li>\
    <li>Leziunile ligamentelor colaterale</li>\
    <li>Tendinita patelară</li>\
    <li>Gonartroza</li>\
    <li>Potezarea genunchiului</li>\
    <li>Chist Beaker</li>\
    <li>Leziuni musculare</li>\
    <li>Leziuni ale coafei mușchilor rotatori</li>\
    <li>Instabilitățile de umăr</li>\
    <li>Disjuncția acromio-claviculară</li>\
    <li>Ruptura mușchiului biceps brahial</li>\
    <li>Impingementul subacromial</li>\
    <li>Epicondilita </li>\
    <li>Fasciinta plantară</li>\
    <li>Osteoporoza</li>\
    <li>Protezare sold</li>\
    <li>Instabilitățile de gleznă</li>\
    <li>Entorsă de gleznă</li>\
    <li>Ruptura tendonului achilean</li>\
    <li>Tendinita achileană</li>\
    <li>Fracturi</li>\
    <li>Scolioza</li>\
    <li>Cifoza</li>\
    <li>Discopatia lombară</li>\
    <li>Hernia de disc</li>\
    </ul>',
    photoUrl: 'DSC_2056.webp',
    videos: []
};