import { terapieTecar } from './terapie-tecar';
import { laserInaltaFrecventa } from './laser-inalta-frecventa';
import { crioterapie } from './crioterapie';
import { electroterapie } from './electroterapie';
import { ultrasunet } from './ultrasunet';
import { drenajLimfatic } from './drenaj-limfatic';
import { testingMuscular } from './testing-muscular';
import { testingArticular } from './testing-articular';
import { stretching } from './stretching';
import { terapieManuala } from './terapie-manuala';
import { masajTerapeutic } from './masaj-terapeutic';
import { recoveryBoots } from './recovery-boots';
import { kinetoterapie } from './kinetoterapie';
import { kinetoterapieTraumatologieSportiva } from './kinetoterapie-traumatologie-sportiva';
import { kinesioTaping } from './kinesio-taping';
import { medicalFlossing } from './medical-flossing';
import { dryNeedling } from './dry-needling';
import { dynamicTape } from './dynamic-tape';
import { bandajareRigida } from './bandajare-rigida';

import { IService } from '@/interfaces/service';

export const servicesMap: { [serviceId: string]: IService } = {
    'terapie-tecar': terapieTecar,
    'laser-inalta-frecventa': laserInaltaFrecventa,
    crioterapie,
    electroterapie,
    ultrasunet,
    'drenaj-limfatic': drenajLimfatic,
    'testing-muscular': testingMuscular,
    'testing-articular': testingArticular,
    stretching,
    'terapie-manuala': terapieManuala,
    'masaj-terapeutic': masajTerapeutic,
    'recovery-boots': recoveryBoots,
    kinetoterapie,
    'kinetoterapie-in-traumatologie-sportiva': kinetoterapieTraumatologieSportiva,
    'kinesio-taping': kinesioTaping,
    'medical-flossing': medicalFlossing,
    'dry-needling': dryNeedling,
    'dynamic-tape': dynamicTape,
    'bandajare-rigida': bandajareRigida
};