import { IService } from '@/interfaces/service';

export const terapieTecar: IService = {
    title: 'Terapie TECARE',
    shortDescription: 'Terapie TECARE',
    description: '<h2><span class="text-primary">Terapia TECARE (Transfer Capacitive Resistive Energy)</span> este una dintre cele mai căutate \
    și revoluționare soluții medicale, folosită cu succes în tratamentele de fizioterapie, prin crearea unei stimulări intense la nivel \
    celular (vindecarea printr-o modalitate rapidă și nedureroasă). Accelerează „regenerarea naturală” a țesuturilor biologice, \
    având totodată un efect antiinflamator. Este folosită pentru tratarea pre și postoperator: în ligamentoplastii, proteze și \
    osteosinteză, a durerilor/leziunilor musculare, afecțiunilor articulare acute sau cronice, patologiilor coloanei vertebrale \
    (hernie de disc, protuzii discale, lombalgii, lombosciatică etc), tendinopatii, ca tratament postoperator în artroscopii, \
    traumatismelor osoase sau ligamentare, în procesele inflamatorii de la nivelul joncțiunii mușchi-tendon, a afecțiunilor \
    posttraumatice (efect analgezic, antiinflamator și antidematos), a luxațiilor/fracturilor.</h2><br>\
    <p>La început, terapia <span class="text-primary">TECAR</span> a fost utilizată în domeniul sportiv, fiind tratați sportivi \
    de performanță pentru a crește viteza de vindecare după diferitele traumatisme suferite.</p>\
    <p class="pt-5 pb-1">Efectele terapiei Tecare:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Stimulează metabolismul celular</li>\
    <li>Efect antialgic, endorfinic</li> \
    <li>Antiinflamator și antiedematos</li> \
    <li>Stimulează drenajul limfatic</li> \
    <li>Efect miorelaxant</li> \
    <li>Reduce spaticitatea</li> \
    </ul> \
    <p class="pt-5 pb-1">Conform studiilor efectuate, impactul terapiei \
    <span class="text-primary">TECAR</span> are următoarele beneficii:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>97% vindecare accelerată</li>\
    <li>81% combate durerea imediat, pe o durată lungă de timp</li> \
    <li>Zona afectată se vascularizează de 6 ori mai mult decât în mod normal ceea ce produce \
    vindecarea rapidă a țesuturilor afectate</li> \
    </ul> \
    <p class="pt-5 pb-1">Există două moduri de lucru, ce pot fi utilizate separat sau consecutiv, \
    în funcție de patologia tratată:</p>\
    <ol class="list-decimal px-10 text-left">\
    <li>Modul capacitiv acționează asupra țesuturilor moi și grupelor de mușchi cu un grad mai mare \
    de hidratare, precum și asupra vaselor de sânge și a celor limfatice. </li>\
    <li>Modul rezistiv acționează asupra țesuturilor și structurilor conjunctive \
    (ligamente, oase,  tendoane, capsule articulare, cartilaje). Terapia TECAR stimulează \
    microcirculația sangvină, crește vasodilatația și temperatura endogenă. O ședință are o \
    durată de aproximativ 15-30 de minute, terapia putând fi utilizată chiar și în timpul exercițiilor \
    de kinetoterapie, cât și în combinație cu alte forme de fizioterapie.</li>\
    </ol>\
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">Contraindicații</span></h3></p>\
    <p>Terapia Tecar poate fi utilizată pentru o gamă largă de afecțiuni. Totuși, există anumite \
    grupe de pacienți cărora le este contraindicat să folosească această terapie: </p>\
    <ul class="list-disc px-10 text-left">\
    <li>Femeilor gravide</li>\
    <li>Pacienților cu:\
    <ul class="list-square px-10 text-left">\
    <li>vârstă mai mică de 14 ani</li>\
    <li>probleme de coagulare a sângelui</li>\
    <li>sensibilitate la caldură</li>\
    <li>tulburări de sensibilitate periferică</li>\
    <li>cancer, epilepsie sau procese infecțioase</li>\
    <li>proteze auditive</li>\
    <li>insuficiență venoasă, tromboflebită</li>\
    <li>tulburări de sensibilitate periferică</li>\
    <li>probleme cardiovasculare cronice (Pacemaker, etc)</li>\
    </ul>\
    </li>\
    </ul>',
    photoUrl: 'DSC_8566.webp',
    videos: []
};