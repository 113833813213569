import { IService } from '@/interfaces/service';

export const masajTerapeutic: IService = {
    title: 'Masaj terapeutic',
    shortDescription: 'Masaj Terapeutic - Terapie prin masaj',
    description: '<h2><span class="text-primary">Masajului terapeutic</span> diferă în funcție de afecțiunea care \
    trebuie tratată și are ca scop eliminarea și eliberarea punctelor ce provoacă durerea musculară, \
    decontracturarea mușchilor și rezolvarea problemelor circulatorii, precum și elasticizarea și \
    relaxarea tendoanelor. Acesta constă în prelucrarea manuală a țesuturilor moi, cu scopul de a \
    stimula circulația sângelui, a limfei și de a decontractura musculatura. Cu ajutorul manevrelor \
    folosite de netezire, frământare, tapotamnet, vibrații și fricțiuni, circulația sângelui este stimulată, \
    iar organismul poate furniza mai mult oxigen și substanțe nutritive în țesuturi și organele vitale.</h2><br>\
    <p>Există diferite tehnici în funcție de obiectivele fiecărui tratament, de afecțiunea pe care trebuie \
    să o tratam și/sau de planul de recuperare/refacere ce trebuie urmat.</p><br>\
    <p><span class="text-primary">Masajul</span> nu trebuie să înlocuiască recuperarea medicală periodică, \
    tocmai de aceea, terapeuții noștri efectuează acest tip de recuperare în mod controlat și în condiții de siguranță.</p>\
    <p><h3 class="font-medium text-primary pt-5 pb-1">Beneficii:</h3></p> \
    <ul class="list-disc px-10 text-left">\
    <li>Relaxează mușchii</li>\
    <li>Reduce spasmele</li>\
    <li>Stimulează secreția endorfinelor</li>\
    <li>Crește flexibilitatea articulațiilor</li>\
    <li>Stimulează limfa</li>\
    <li>Intensifică circulația sângelui, </li>\
    <li>Scurtează semnificativ perioadele de recuperare medicală sau refacere postefort</li>\
    <li>Este o activitate extrem de eficientă pentru sănătatea corpului</li>\
    </ul>',
    photoUrl: '2.jpg',
    videos: []
};