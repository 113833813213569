import { IService } from '@/interfaces/service';

export const dynamicTape: IService = {
    title: 'Dynamic Tape',
    shortDescription: 'Dynamic Tape',
    description: '<h2>Este realizată dintr-un material unic care se comportă ca o coardă de bungee jumping. \
    Are un design special creat pentru a sărbători cultura aborigeană și o multitudine de aplicații practice, cu un rezultat \
    imediat pentru pacient. Poziția de pornire este una scurtată, tape-ul întins, astfel încât, pe măsură ce mișcarea \
    devine mai amplă, <span class="text-primary">Dynamic Tape</span> are rolul de a absorbi încărcarea provenită din modelul \
    de mișcare, activând procesul de decelerare. Odată ce procesul de decelerare este complet, energia potențială conservată \
    este retransmisă în sistemul musculoscheletal.</h2><br>\
    <p>Produs dintr-un material vâsco-elastic ce permite să se deformeze în timp, absoarbe energia și își revine apoi \
    la forma inițială. Odată aplicată, această bandă poate influența factorul dureros prin diminuarea stimulării \
    proprioceptive la nivel periferic, poate influența poziția biomecanică în articulații și poate influența circulația \
    limfatică și sangvină.</p><br>\
    <p><span class="text-primary">Dynamic Tape</span> - singurul tape biomecanic din lume, cu proprietăți diferite față de \
    alte tape-uri, oferă un suport mecanic și decelerarea forțelor în timp real.</p><br>\
    <p>Practic, <span class="text-primary">Dynamic Tape</span> acționează ca un arc asupra sistemului \
    <span class="text-primary">Neuro-Mio-Artro-Cinematic</span>, asistând pe toată amplitudinea de mișcare, absoarbe \
    sarcina exercitată (ca o coarda de bungee jumping), modifică modelul de mișcare dacă este necesar și oferă o corecție \
    biomecanică, rolul principal al acestui tape fiind unul biomecanic. Obiectivul principal este acela de a modifica \
    mișcarea și de a asista membrul superior pe toată cursa de mișcare pentru a scădea durerea fiecărui pacient, \
    prin abordarea biomecanică. Odată aplicată, banda biomecanică reduce sacina mecanică (prin descărcarea brațului/a \
    pârghiei) și îmbunătățește eficiența, ceea ce are ca rezultat un efort mai mic al mușchiului și o oboseală mai scăzută. \
    <span class="text-primary">Dynamic Tape</span> îmbunătățește modelul mișcării prin absorbția încărcarii provenite din \
    tiparul de mișcare și detensionarea țesuturilor moi prin decelerare. Această bandă biomencanică poate influența \
    factorul dureros prin corecția biomecanică și prin diminuarea ei datorită stimulării proprioceptive.</p><br>\
    <p>Are proprietatea de a-și distribui în mod egal forța vectorului pe toată suprafața benzii atât pe lungimea, \
    cât și pe lățimea ei, ceea ce produce un model mai bun al mișcării prin influența poziției biomecanice la nivel articular.</p>',
    photoUrl: 'DSC_8548.webp',
    videos: []
};