import { IService } from '@/interfaces/service';

export const electroterapie: IService = {
    title: 'Electroterapie',
    shortDescription: 'Electroterapie - Electrostimulare Neuromusculară',
    description: '<h2><span class="text-primary">Electroterapia</span> este o formă de terapie care utilizează \
    stimularea electrică, cu scopul reducerii senzației de durere (articulară sau musculară) sau în procesul \
    de vindecare a țesuturilor. Această metodă menține durerea sub control, contribuie la accelerarea procesului \
    de vindecare a traumatismelor, acționează asupra sistemului muscular și nervos, producând efecte termice \
    benefice, de tip analgezic.</h2>\
    <p class="pt-5 pb-1"><span class="text-primary">TENS (Stimularea electrică nervoasă transcutanată)</span> \
    este folosită în combaterea diferitelor patologii, precum:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Artroze</li>\
    <li>Dureri lombare</li>\
    <li>Oboseală musculară</li>\
    <li>Dureri provocate de afecțiuni nervoase</li>\
    <li>Dureri cervicale</li>\
    <li>Dureri articulare</li>\
    <li>Contuzii</li>\
    <li>Entorse</li>\
    <li>Luxații</li>\
    <li>Hematoame</li>\
    <li>Dureri ale coloanei vertebrale</li>\
    <li>Leziuni musculare</li>\
    </ul> \
    <p class="pt-5 pb-1">Cu ajutorul dispozitivului medical se realizează (prin electrozi lipiți pe piele în zona \
    dureroasă) o stimulare electrică de mică intensitate (nedureroasă pentru organism):</p>\
    <ol class="list-decimal px-10 text-left">\
    <li>Stimularea cu frecvență înaltă, prin excitarea nervoasă a unor fibre ce nu provoacă durerea, \
    trimițând către creier informații ce vor stopa alte excitații nervoase ce provoacă durere. </li>\
    <li>Stimularea cu frecvențe joase creează endorfine, ce fac parte din grupul peptidelor, \
    substanțe chimice care sunt produse natural de sistemul nervos central, pentru a face față stresului și durerii.</li>\
    </ol><br>\
    <p><span class="text-primary">TENS</span> nu provoacă dependență și prezintă puține efecte adverse.</p>\
    <p class="pt-5 pb-1">Este contraindicat:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Persoanelor epileptice</li>\
    <li>Pacienților cu aparate de tip pacemaker sau care suferă de alte probleme cardiace</li>\
    <li>Pacienților la care sursa durerii nu este documentată</li>\
    <li>Persoanelor care au numite patologii dermatologice</li>\
    <li>Gravidelor (pentru folosirea pe anumite zone)</li>\
    </ul> \
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">Terapia interferențială (CIF)</span></h3></p>\
    <p>Sunt utilizați doi curenți de frecvență înaltă, transmiși în același timp, intersectându-se și \
    interferând unul cu celălalt.</p>\
    <p class="pt-5 pb-1">Are ca scop:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Scăderea durerii </li>\
    <li>Stimularea musculară </li>\
    <li>Stimularea fluxului sangvin</li>\
    <li>Reducerea edemului local</li>\
    </ul> \
    ',
    photoUrl: 'DSC_2010.webp',
    videos: []
};