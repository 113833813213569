import { IService } from '@/interfaces/service';

export const bandajareRigida: IService = {
    title: 'Bandajare Rigida',
    shortDescription: 'Recuperare medicală prin bandajare rigidă',
    description: '<h2>Reprezintă un mix de tehnici de bandajare, în care, pe lângă <span class="text-primary">kinesiotaping</span> \
    și <span class="text-primary">dynamic tape</span>, putem utiliza împreună sau separat benzi adezive rigide, benzi adezive \
    ușor elastice sau bandaje elastice adezive.</h2><br>\
    <p>Folosim aceste tipuri de bandajare în special la sportivi, fie ei amatori sau de înaltă performanță pentru a \
    fixa o articulație înainte de antrenamente sau competiții sportive. Totuși, există și situații când le utilizam în \
    timpul/sau după ședințele de fizio-kinetoterapie</p>',
    photoUrl: 'DSC_4242.webp',
    videos: []
};