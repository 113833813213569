import { IService } from '@/interfaces/service';

export const kinesioTaping: IService = {
    title: 'Kinesio Taping',
    shortDescription: 'Kinesio Taping - Benzi Kinesiologice',
    description: '<h2>Povestea acestei tehnici de bandajare începe în anii ’70, când doctorul <span class="text-primary">Kenzo Kase</span> \
    a inventat <span class="text-primary">kinesio taping-ul</span> pentru a-i ajuta pe sportivii aflați în procesul de \
    recuperare medicală după traumele suferite în timpul activităților sportive.</h2><br>\
    <p><span class="text-primary">Banda kinesiologică</span> poate fi utilizată pe diverse grupe de pacienți, de la \
    pediatrie până la geriatrie, tratând o varietate de afecțiuni ortopedice, neurologice și neuromusculare.</p><br>\
    <p>Se utilizează o bandă elastică din bumbac și un strat adeziv hipoalergic, ce este aplicat în așa fel încât \
    îi permite materialului să respire (rareori produce iritații dacă este aplicată corect). Acest tip de bandaj \
    poate fi utilizat 2-3 zile, după care își pierde din proprietăți și trebuie schimbat. Rezistă la apă – există \
    și variante cu rezistență mare, concepută pentru bazinul de înot.</p><br>\
    <p><span class="text-primary">Banda kinesiologică</span> scade durerea și facilitează drenajul limfatic prin ridicarea \
    microscopică a pielii, formând striații, cutanat si subcutanat. Astfel, apar spații interstițiale mai mari și rezultă \
    un aport de oxigen care conduce la îmbunătățirea circulației, dar și la o scădere a inflamației. Există sute de tipuri \
    de bandaje care au capacitatea de a calma durerea și de a reduce inflamația, de a crește performanța sportivă, de a \
    reeduca sistemul neuromuscular prin îmbunătățirea circulației. Ele pot fi utilizate și în scop profilactic.</p><br>\
    <p><span class="text-primary">Kinesio Taping</span> poate fi utilizat și în asociere cu alte tipuri de tratamente \
    sau tipuri de bandajare. Este o tehnică de bandajare adezivă ce ajută procesul de vindecare naturală a organismului, \
    împreună cu stabilizarea articulațiilor și a mușchilor, fără a limita amplitudinea mișcărilor.</p><br>\
    <p>Astăzi, <span class="text-primary">Kinesio Taping</span> se utilizează în spitale, clinici, precum și în cadrul \
    echipelor medicale ce se ocupă de sportivii de performanță și înaltă performanță din întreaga lume (tenis, fotbal, \
    înot, baschet, handbal, polo, volei, ciclism, schi, atletism etc).Tratamentul poate fi efectuat numai de către personal \
    medical specializat în acest tip de bandaje (existând și contraindicații) si nu ține locul unor intervenții chirurgicale.</p><br>\
    <p>Terapeuții noștri aplică aceste benzi adezive, care sunt în același timp și hipoalergenice, pentru a putea trata \
    leziunile atletice și o varietate de alte tulburări fizice. Pot fi folosite pe toată durata competițiilor sportive, \
    pentru a cuprinde o patologie mai largă.</p>\
    <p class="pt-5 pb-1">Această metodă se folosește în afecțiuni multiple:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Leziuni musculare</li>\
    <li>Tendinite</li>\
    <li>Dureri de spate</li>\
    <li>Drenaj</li>\
    <li>Entorse și luxații</li>\
    <li>Edem</li>\
    <li>Afecțiuni postoperatorii</li>\
    </ul>\
    <p class="pt-5 pb-1">Beneficii:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>Reduce durerile musculare și inflamația </li>\
    <li>Ameliorează semnificativ durerea</li>\
    <li>Oferă susținere suplimentară pentru articulații și mușchi</li>\
    <li>Facilitează drenajul limfatic</li>\
    </ul>',
    photoUrl: 'DSC_2117.webp',
    videos: []
};