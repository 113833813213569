import { IService } from '@/interfaces/service';

export const medicalFlossing: IService = {
    title: 'Medical Flossing',
    shortDescription: 'Recuperare medicală folosind bandă medicală flossing',
    description: '<h2><span class="text-primary">Flossingul</span> este o bandă de latex, folosită ca metodă de recuperare \
    medicală și are ca scop îmbunătățirea mobilității articulațiilor și creșterea \
    fluxului sanguin la nivelul țesuturilor. Tactica aplicată de către terapeuții \
    noștri reprezintă comprimare, tensiune și mișcare.</h2>\
    <p><h3 class="font-medium text-primary pt-5 pb-1">Beneficii</h3></p> \
    <ul class="list-disc px-10 text-left">\
    <li>Cresterea mobilității articulare</li>\
    <li>Diminuarea durerii</li> \
    <li>Îmbunătățirea calității mișcării</li> \
    <li>Creșterea fluxului sangvin și scăderea edemului</li> \
    </ul>',
    photoUrl: 'DSC_4224.webp',
    videos: []
};