import { IService } from '@/interfaces/service';

export const kinetoterapieTraumatologieSportiva: IService = {
    title: 'Kinetoterapie in Traumatologie Sportiva',
    shortDescription: 'Kinetoterapie in Traumatologie Sportivă',
    description: '<h2>Ne place să lucrăm cu sportivii deoarece ne place să contribuim la \
    performanțele lor. Astfel, terapeuții <span class="text-primary">MovKinetic</span> elaborează tratamente complexe \
    individualizate pentru sportivii care necesită recuperare medicală. \
    <span class="text-primary">Kinetoterapia în traumatologie sportivă</span> urmărește restabilirea capacității funcționale și dezvoltarea \
    unor mecanisme compensatorii pierdute de un individ în urma unui traumatism. Prin acest \
    serviciu, terapeuții <span class="text-primary">MovKinetic</span> studiază atent afecțiunile traumatice ale unui sportiv \
    apărute în urma exercițiilor fizice. Pentru sportivii de performanță această terapie \
    are un specific aparte ca urmare a particularităților biologice.</h2><br>\
    <p>Programele de recuperare prin sport pe care le propunem pentru pacienții \
    noștri sunt definite de utilizarea unui complex de factori naturali sau \
    artificiali în scopul restabilirii autonomiei unei grupe de mușchi. De asemenea, \
    kinetoterapeuții noștri sunt pregătiți intens să poată educa și să creeze programe \
    complexe privind recuperarea în activitățile sportive.</p><br>\
    <p>Experiența <span class="text-primary">MovKinetic</span> cuprinde sute de pacienți tratați și vindecați lucru \
    care ne recomandă pentru cele mai bune rezultate în ceea ce privește recuperarea \
    medicală și pentru implementarea precisă a programelor de kinetoterapie.</p>',
    photoUrl: 'DSC_1845.webp',
    videos: []
};