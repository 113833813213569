import { IService } from '@/interfaces/service';

export const terapieManuala: IService = {
    title: 'Terapie Manuala',
    shortDescription: 'Terapie Manuală',
    description: '<h2>Kinetoterapeuții noștri pot identifica și trata “defecte” temporare ale organismului \
    ( zone care sunt afectate și necesită o atenție sporită).</h2>\
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">Cum?</span></h3></p>\
    <p>Prin <span class="text-primary">terapia manuală</span> complex și specifică. \
    <span class="text-primary">Terapia manuală</spam> se referă la tehnici și manevre executate cu ajutorul \
    mâinilor sau folosind anumite instrumente pentru a: </p>\
    <ul class="list-disc px-10 text-left">\
    <li>reduce tensiunile și durerile musculare</li>\
    <li>îmbunatăți funcțiile articulare ale membrelor sau coloanei vertebrale</li>\
    <li>crește elasticitatea tendoanelor</li>\
    <li>realiza recuperarea posttraumatică</li>\
    <li>facilita recuperarea postefort</li>\
    </ul><br> \
    <p>Această terapie se realizează local în funcție de problema pe care o are pacientul, iar \
    programul de recuperare propus este personalizat în funcție de afecțiunile fiecărui pacient. \
    Pentru o eficiență maximă și grăbirea procesului de vindecare, terapeuții noștri combină \
    <span class="text-primary">terapia manuală</span> cu proceduri fizio sau tehnici de kinetoterapie.</p>\
    <p class="pt-5 pb-1">Terapia manuală cuprinde tehnci complexe precum:</p>\
    <ul class="list-disc px-10 text-left">\
    <li>DEEP TISSUE MASAJ</li>\
    <li>MANIPULARI</li>\
    <li>TERAPIA TRIGGER POINT </li>\
    <li>JOINT TECHNIQUES</li>\
    </ul> \
    ',
    photoUrl: 'DSC_4216.webp',
    videos: []
};