
import { Component, Vue, Watch } from 'vue-property-decorator';
import Footer from '@/components/Footer.vue';
import { servicesMap } from '@/maps/services/services';
import { IService } from '@/interfaces/service';

@Component({
    name: 'Services',
    components: {
        Footer,
    },
})
export default class Services extends Vue {
    public service: IService | null = null;

    created() {
        this.updateService();
    }

    @Watch('$route')
    onRouteChanged() {
        this.updateService();
    }

    updateService() {
        const service = servicesMap[this.$route.params.id];
        if (!service) {
            this.$router.push('/pagina-inexistenta');
        }

        this.service = service;
    }
}
