import { IService } from '@/interfaces/service';

export const dryNeedling: IService = {
    title: 'Dry Needling',
    shortDescription: 'Dry Needling - Terapia acelor uscate',
    description: '<h2><span class="text-primary">Terapia Dry Needling</span> sau <span class="text-primary">Terapia Acelor Uscate</span> \
    este o tehnică folosită pentru scăderea durerilor musculare. Durerea poata să se diminueze cu până la \
    50%, chiar de la prima ședință. <span class="text-primary">Dry needling-ul</span> este o tehnică medicală ce folosește \
    ace foarte subțiri și care, folosindu-se de anatomie, fiziologie și biochimie, reușește să accelereze metabolismul local \
    și să pornească mecanismul de vindecare. Pacienții simt o mică înțepătură, dar după primele 20-30 de secunde, \
    acele nu se mai simt. Există cazuri în care procedura creează o durere moderată - când acul rupe anumite fibre musculare.</h2><br>\
    <p>Tehnica este una minimum invazivă și 100 % sterilă - se folosesc mănuși și se dezinfectează zona unde urmează \
    să fie introduse acele (de unică folosință).</p><br>\
    <p>Tehnica în sine ajută doar la scăderea durerii și la creșterea mobilității, fară a trata cauza. Ajută atât \
    pacientul, cât și fizioterapeutul în procesul de recuperare medicală.</p>',
    photoUrl: '2.jpg',
    videos: []
};